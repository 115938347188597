import styles from "../styles/Avatar.module.css";
import Button from "../components/button.js";
import { useNavigate } from "react-router-dom";
export default function QuestionStart() {
  const navigate = useNavigate();
  function goToQuestionnaire() {
    navigate("/q-name");
  }
  return (
    <div className="base__mobile__container">
      <div className="mobile__preview transparent_bg">
        <div className={styles.base__container}>
          <img
            className={styles.final__image}
            src="/avatar-examples.gif"
            alt="example__avatar"
          />
          <div className={styles.text__container}>
            <div className={styles.title}>Welcome!</div>
            <div className={styles.subtext}>
              Let's build your avatar together.
            </div>
            <div className={styles.body__text}>
              Your unique avatar will accompany you on your journey, to unlock
              opportunities and redefine your career with Talent Pavilion.
            </div>
            <Button
              text="Let's Get Started!"
              clickHandler={goToQuestionnaire}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
