import styles from "./../../../styles/AvatarDisplay.module.css";
import cx from "classnames";
import { connect, useDispatch } from "react-redux";
import { hairTypeOptions } from "../../../dataTypes";
import { UPDATE_AVATAR_PART } from "../../../redux/actionTypes";

const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function HairSelection(state) {
  const dispatch = useDispatch();
  const avatarPart = "hair";
  function updateAvatarPart(part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: avatarPart,
        selection: part,
      },
    });
  }

  function compareAvatar(cur, part) {
    return cur === part;
  }
  return (
    <div className={styles.selection__container}>
      <div className={styles.selection__grid}>
        <div
          className={cx(styles.selection, {
            [styles.selection__selected]: compareAvatar(
              state.model[avatarPart],
              ""
            ),
          })}
          onClick={() => updateAvatarPart("")}
        >
          <img
                className={styles.contained__image}
                src={`/icons/None.svg`}
                alt={`hair-none`}
              />
        </div>
        {hairTypeOptions.map((hair, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model[avatarPart],
                  hair
                ),
              })}
              onClick={() => updateAvatarPart(hair)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${hair}.png`}
                alt={`hair-${index}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(HairSelection);
