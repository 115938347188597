import './App.css';
import { Routes, Route } from "react-router-dom";
import QuestionStart from "./pages/q-start";
import QuestionName from './pages/q-name';
import QuestionRole from './pages/q-role';
import QuestionAge from "./pages/q-age";
import QuestionInterest from './pages/q-interest';
import PresetSelection from './pages/preset-selection';
import BuildMe from './pages/build-me';
import QuestionEmail from './pages/q-email';
import Finale from "./pages/finale";

function App() {
  return (
    <div className="App">
      <div className="App">
      <Routes>
        <Route path="/" element={ <QuestionStart/> } />
        <Route path="/q-name" element={ <QuestionName/> } />
        <Route path="/q-role" element={<QuestionRole/>} />
        <Route path="/q-age" element={<QuestionAge/>} />
        <Route path="/q-interest" element={<QuestionInterest/>} />
        <Route path="/preset-selection" element={<PresetSelection/>} />
        <Route path="/build-me" element={<BuildMe/>} />
        <Route path="/q-email" element={<QuestionEmail/>} />
        <Route path="/finale" element={<Finale/>} />
      </Routes>
    </div>
    </div>
  );
}

export default App;
