import styles from "../../styles/AvatarDisplay.module.css";
import cx from "classnames";
import { connect, useDispatch } from "react-redux";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import { FINISH_AVATAR } from "../../redux/actionTypes";
const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function AvatarDisplay(state) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let className = cx(styles.avatar__display, {
    [styles.bust]: state.view === "bust",
    [styles.full]: state.view === "full",
  });

  function checkNotEmpty(obj) {
    return obj !== "";
  }
  function finishAvatarHandler() {
    const avatarBuilt = Object.entries(state.model).every(([k, v]) => {
      switch (k) {
        case "clothesTop":
          return state.model.clothesSet !== "" || v !== "";
        case "clothesBottom":
          return state.model.clothesSet !== "" || v !== "";
        case "shoes":
          return state.model.clothesSet !== "" || v !== "";
        case "clothesSet":
          return (
            (state.model.clothesTop !== "" &&
              state.model.clothesBottom !== "" &&
              state.model.shoes !== "") ||
            v !== ""
          );
        case "hair":
          return true;
        case "ascBeard":
          return true;
        case "ascMoustache":
          return true;
        case "ascSpectacles":
          return true;
        case "ascEarrings":
          return true;
        default:
          return v !== "";
      }
    });
    dispatch({
      type: FINISH_AVATAR,
      payload: {
        requiredModalState: !avatarBuilt
      }
    })
    if (avatarBuilt) {
      navigate("/q-email");
    }
  }
  return (
    <div className={styles.avatar__display__container}>
      <div className={styles.random__button}>
        <Button text="Next" clickHandler={() => finishAvatarHandler()} />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.bodyType)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.bodyType}.png`
              : "/transparent-holder.png"
          }
          alt="bodyType"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.transport)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.transport}.png`
              : "/transparent-holder.png"
          }
          alt="transport"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.shoes)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.shoes}.png`
              : "/transparent-holder.png"
          }
          alt="shoes"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.clothesBottom)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.clothesBottom}.png`
              : "/transparent-holder.png"
          }
          alt="clothes_bottom"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.clothesTop)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.clothesTop}.png`
              : "/transparent-holder.png"
          }
          alt="clothes_top"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.clothesSet)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.clothesSet}.png`
              : "/transparent-holder.png"
          }
          alt="clothes_set"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.mouth)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.mouth}.png`
              : "/transparent-holder.png"
          }
          alt="mouth"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.eyes)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.eyes}.png`
              : "/transparent-holder.png"
          }
          alt="eyes"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.hair)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.hair}.png`
              : "/transparent-holder.png"
          }
          alt="hair"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.ascEarrings)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.ascEarrings}.png`
              : "/transparent-holder.png"
          }
          alt="asc_earrings"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.ascBeard)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.ascBeard}.png`
              : "/transparent-holder.png"
          }
          alt="asc_beard"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.ascMoustache)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.ascMoustache}.png`
              : "/transparent-holder.png"
          }
          alt="asc_earrings"
        />
      </div>
      <div className={className}>
        <img
          className={styles.contained__image}
          src={
            checkNotEmpty(state.model.ascSpectacles)
              ? `/avatar-builder/${state.type}/${state.type}-${state.model.ascSpectacles}.png`
              : "/transparent-holder.png"
          }
          alt="asc_spectacles"
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(AvatarDisplay);
