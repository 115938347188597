import { useState } from "react";
import styles from "../styles/Questionnaire.module.css";
import Button from "../components/button";
import QuestionBase from "../components/questionBase";
import { connect, useDispatch } from "react-redux";
import { UPDATE_INTEREST } from "../redux/actionTypes";
import { RESET_AVATAR } from "../redux/actionTypes";
import SelectionButton from "../components/selectionButton";
import { useNavigate } from 'react-router-dom';
import ErrorModal from "../components/errorModal";
const mapStateToProps = (state) => {
  return { ...state.questions };
};

function QuestionInterest(state) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [interest, setInterest] = useState(state.interest);
  const selectOptions = [
    "Up-skilling",
    "Training",
    "Career guidance",
    "Talent Development",
    "Salary Support",
    "Job Opportunities",
  ];
  function setInterestHandler(selection) {
    let updatedInterest = [...interest];
    let foundIdx = updatedInterest.findIndex((el) => el === selection);
    if (foundIdx > -1) {
      updatedInterest.splice(foundIdx, 1);
    } else {
      updatedInterest.push(selection);
    }
    setInterest(updatedInterest);
  }
  function submitHandler(value, dispatchAction) {
    if (state.role !== value) {
      dispatch({
        type: RESET_AVATAR,
      });
    }
    dispatch({
      type: dispatchAction,
      payload: {
        answer: value,
      },
    });
    navigate("/preset-selection");
  }
  return (
    <div className="base__mobile__container">
      <div className="mobile__preview">
        {state && ( !state.name || !state.role || !state.age )? <ErrorModal text="Lost previous data"/> : null }
        <div className={styles.base__container}>
          <div className={styles.answer__container}>
            <QuestionBase
              title={
                <span>
                  Which areas do you want to <br />
                  find out more about?
                </span>
              }
            >
              <div className={styles.mcq__container}>
                {selectOptions.map((el, index) => {
                  return (
                    <SelectionButton
                      key={index}
                      text={el}
                      multi
                      fill
                      outline={!interest.includes(el)}
                      checked={interest.includes(el)}
                      clickHandler={() => setInterestHandler(el)}
                    />
                  );
                })}
              </div>
            </QuestionBase>
          </div>
          <div className={styles.button__container}>
            <Button
              text="Next"
              fill
              disabled={!interest.length}
              clickHandler={() =>
                submitHandler(interest, UPDATE_INTEREST)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(QuestionInterest);;
