import { useState } from "react";
import styles from "../styles/Questionnaire.module.css";
import Button from "../components/button";
import QuestionBase from "../components/questionBase";
import { connect, useDispatch } from "react-redux";
import { UPDATE_NAME } from "../redux/actionTypes";
import { useNavigate } from 'react-router-dom';

const mapStateToProps = (state) => {
  return { ...state.questions };
};

function QuestionName(state) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState(state.name);
  function onKeyUp(e){
    e.preventDefault();
    if (e.keyCode === 13) {
      console.log("trigger");
      submitHandler(name, UPDATE_NAME);
    }
  }
  function submitHandler(value, dispatchAction) {
    dispatch({
      type: dispatchAction,
      payload: {
        answer: value,
      },
    });
    navigate("/q-role");
  }
  return (
    <div className="base__mobile__container">
      <div className="mobile__preview">
        
        <div className={styles.base__container}>
          <div className={styles.answer__container}>
            <QuestionBase
              title={
                <span>
                  First up, how can we
                  <br />
                  address you?
                </span>
              }
            >
              <input
                placeholder="your name (16 chars)"
                autoComplete="name"
                className={styles.input__container}
                value={name}
                maxLength={16}
                onChange={(e) => setName(e.target.value)}
                onKeyUp={(e) => onKeyUp(e)}
              />
            </QuestionBase>
          </div>
          <div className={styles.button__container}>
            <Button
              text="Next"
              fill
              disabled={!name}
              clickHandler={() =>
                submitHandler(name, UPDATE_NAME)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(QuestionName);
