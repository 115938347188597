import { connect } from "react-redux";
import styles from "../styles/Avatar.module.css";
import React, { Component } from "react";
import ErrorModal from "./../components/errorModal";
import mergeImages from "./../hooks/merge-images";
import Button from "./../components/button";
import { FORM_SENDING, FORM_SEND_SUCCESS, FORM_SEND_ERROR } from "./../redux/actionTypes";
import axios from "axios";

const mapStateToProps = (state) => {
  return { ...state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    formSend: ()=> dispatch({type: FORM_SENDING}),
    formSent: ()=> dispatch({type: FORM_SEND_SUCCESS}),
    formError: ()=> dispatch({type: FORM_SEND_ERROR}),
  }
}

const dateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
}

// test zapier api
const zapOptions = {
  "PMET": "https://hooks.zapier.com/hooks/catch/13604618/b0qxbb2/",
  "Student/Youth/Fresh graduate": "https://hooks.zapier.com/hooks/catch/13604618/bxi7qpk/",
  "Career switcher / job seeker": "https://hooks.zapier.com/hooks/catch/13604618/bxi7lx7/"
}

class Finale extends Component {
  constructor(props) {
    super(props);
    this.downloadImage = this.downloadImage.bind(this);
    this.enterWall = this.enterWall.bind(this);
  }
  state = {
    mergedImage: "",
  };
  checkNotEmpty(obj) {
    return obj !== "";
  }
  componentDidMount() {
    if (this.props.avatar) {
      let allImages = [];
      for (const v of Object.values(this.props.avatar.model)) {
        if (this.checkNotEmpty(v)) {
          allImages.push({
            src: `/avatar-builder/${this.props.avatar.type}/${this.props.avatar.type}-${v}.png`,
            x: 0,
            y: 0,
          });
        }
      }

      mergeImages(allImages, {
        width: 660,
        height: 930,
      }).then((b64) => {
        this.setState({
          ...this.state,
          mergedImage: b64,
        });
      });
    }
  }

  enterWall() {
    function dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    }
    if (this.props.questions && this.props.form) {
      //const imageTest = dataURItoBlob(this.state.mergedImage);
      var bodyData = new FormData();
      bodyData.append("date_time", new Date().toLocaleString("en-ZA", dateOptions).replace(/[/,:]/g,'').replaceAll(' ','—'));
      bodyData.append("email_address", this.props.questions.email);
      bodyData.append("name", this.props.questions.name);
      bodyData.append("type", this.props.avatar.type);
      bodyData.append("role", this.props.questions.role);
      bodyData.append("age", this.props.questions.age);
      bodyData.append("interest", this.props.questions.interest.toString());
      bodyData.append("model", JSON.stringify(this.props.avatar.model));
      bodyData.append("image_test", dataURItoBlob(this.state.mergedImage));
      
      if(!this.props.form.sending && !this.props.form.formSent){
        axios.post(
          zapOptions[this.props.questions.role],
        bodyData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then(() => {
        this.props.formSent();
        alert("Email sent!");
      });
        this.props.formSend();
      }
    }
  }
  downloadImage() {
    var a = document.createElement("a");
    a.href = this.state.mergedImage;
    a.download = "Image.png";
    a.click();
  }
  render() {
    return (
      <div className="base__mobile__container">
        <div id="mobile-preview" className="mobile__preview transparent_bg">
          {this.props && !this.props.questions.email ? (
            <ErrorModal text="Lost previous data" />
          ) : null}
          <div className={styles.base__container}>
            {this.props && this.state.mergedImage ? (
              <img
                className={styles.final__image}
                src={this.state.mergedImage}
                alt="final_merge"
              />
            ) : null}
            <Button text="Get more information" clickHandler={this.enterWall} />
            <Button
              red
              blacktext
              text="Download here"
              clickHandler={this.downloadImage}
            />
            <div className={styles.text__container}>
              <div className={styles.subtext}>
                Share your avatar on social media{" "}
                <span className={styles.tag}>#talentpavilion2022</span>
              </div>
              <div className={styles.body__text}>
                At our physical event? Watch your avatar come to life on the
                interactive wall — and follow along as its journey!
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Finale);
