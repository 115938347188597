export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_AGE = "UPDATE_AGE";
export const UPDATE_INTEREST = "UPDATE_INTEREST";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_AVATAR_PART = "UPDATE_AVATAR_PART";
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const RANDOMISE_AVATAR = "RANDOMISE_AVATAR";
export const START_FROM_SCRATCH = "START_FROM_SCRATCH";
export const START_FROM_PRESET = "START_FROM_PRESET";
export const RESET_AVATAR = "RESET_AVATAR";
export const SET_VIEW = "SET_VIEW";
export const SELECT_BODYTYPE = "SELECT_BODYTYPE";
export const SET_PRELOAD_STATE = "SET_PRELOAD_STATE";
export const UPDATE_WARNING_STATE = "UPDATE_WARNING_STATE";
export const FINISH_AVATAR = "FINISH_AVATAR";
export const CLEAR_SERVICE_DETAILS = "CLEAR_SERVICE_DETAILS";
export const FORM_SEND_SUCCESS = "FORM_SEND_SUCCESS";
export const FORM_SEND_ERROR = "FORM_SEND_ERROR";
export const FORM_SENDING = "FORM_SENDING";
export const CLEAR_FORM = "CLEAR_FORM";
export const MERGE_IMAGE = "MERGE_IMAGE";
export const CLOSE_ERROR_MODAL_STATE = "CLOSE_ERROR_MODAL_STATE";