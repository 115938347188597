import styles from "./../../../styles/AvatarDisplay.module.css";
import cx from "classnames";
import { connect, useDispatch } from "react-redux";
import { UPDATE_AVATAR_PART } from "../../../redux/actionTypes";
import { shoesTypeOptions } from "../../../dataTypes";
const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function ShoesSelection(state) {
  const dispatch = useDispatch();
  const avatarPart = "shoes";
  function updateAvatarPart(part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: avatarPart,
        selection: part,
      },
    });
  }

  function compareAvatar(cur, part) {
    return cur === part;
  }
  return (
    <div className={styles.selection__container}>
      <div className={styles.selection__grid}>
        {shoesTypeOptions.map((shoe, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model[avatarPart],
                  shoe
                ),
              })}
              onClick={() => updateAvatarPart(shoe)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${shoe}.png`}
                alt={`shoe-${index}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default connect(mapStateToProps)(ShoesSelection);
