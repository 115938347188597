import styles from "../styles/Questionnaire.module.css";
export default function QuestionBase({title,children}) {
  return (
    <div className={styles.question}>
      <div className={styles.title}>
        {title}
      </div>
      {children}
    </div>
  )
  }
  