import styles from "../styles/Buttons.module.css";
import cx from "classnames";
export default function Button(props) {
  let className = cx(styles.button__style, {
    [props.red ? styles.outlined__red__button : styles.outlined__button]:
      props.outline,
    [props.red ? styles.contained__red__button : styles.contained__button]:
      !props.outline,
    [styles.fill__container]: props.fill,
    [styles.black__text] : props.blacktext,
    [styles.white__text] : !props.blacktext
  });

  return (
    <button
      disabled={props.disabled}
      className={className}
      onClick={() => {
        props.clickHandler();
      }}
    >
      {props.text}
    </button>
  );
}
