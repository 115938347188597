import { connect } from "react-redux";
import PresetSelector from "../components/avatarSelection/presetSelector";
import ErrorModal from './../components/errorModal';
const mapStateToProps = (state) => {
  return { ...state };
};

function PresetSelection(state) {
  return (
    <div className="base__mobile__container">
      <div className="mobile__preview">
      {state &&
        (!state.questions.name ||
          !state.questions.role ||
          !state.questions.age || !state.questions.interest) ? (
          <ErrorModal text="Lost previous data"/>
        ) : null}
        <PresetSelector type={state.questions.role} />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(PresetSelection);;
