import {configureStore} from '@reduxjs/toolkit';
import questionTrackerReducer from './reducers/questions';
import avatarTrackerReducer from './reducers/avatar';
import formReducer from './reducers/form';
const store = configureStore({
    reducer:{
        questions: questionTrackerReducer,
        avatar: avatarTrackerReducer,
        form: formReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',

});

export {store};