import styles from "../../../styles/AvatarDisplay.module.css";
import cx from "classnames";
import { connect, useDispatch } from "react-redux";
import { mouthTypeOptions } from "./../../../dataTypes";
import { UPDATE_AVATAR_PART } from "./../../../redux/actionTypes";

const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function MouthSelection(state) {
  const dispatch = useDispatch();
  const avatarPart = "mouth";
  function updateAvatarPart(part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: avatarPart,
        selection: part,
      },
    });
  }

  function compareAvatar(cur, part) {
    return cur === part;
  }
  return (
    <div className={styles.selection__container}>
      <div className={styles.selection__grid}>
        {mouthTypeOptions.map((mouth, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model[avatarPart],
                  mouth
                ),
              })}
              onClick={() => updateAvatarPart(mouth)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${mouth}.png`}
                alt={`mouth-${index}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(MouthSelection);
