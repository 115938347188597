import { useState } from "react";
import styles from "../styles/Questionnaire.module.css";
import Button from "../components/button";
import QuestionBase from "../components/questionBase";
import { connect, useDispatch } from "react-redux";
import { UPDATE_AGE } from "../redux/actionTypes";
import { RESET_AVATAR } from "../redux/actionTypes";
import SelectionButton from "../components/selectionButton";
import { useNavigate } from 'react-router-dom';
import ErrorModal from './../components/errorModal';
const mapStateToProps = (state) => {
  return { ...state.questions };
};

function QuestionAge(state) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [age, setAge] = useState(state.age);
  const selectOptions = [
    "15-20 Years Old",
    "21-25 Years Old",
    "26-30 Years Old",
    "31-35 Years Old",
    "36-40 Years Old",
    "41-45 Years Old",
    "46+ Years Old",
  ];
  function submitHandler(value, dispatchAction) {
    if (state.role !== value) {
      dispatch({
        type: RESET_AVATAR,
      });
    }
    dispatch({
      type: dispatchAction,
      payload: {
        answer: value,
      },
    });
    navigate("/q-interest");
  }
  return (
    <div className="base__mobile__container">
      <div className="mobile__preview">
        {state && (!state.name || !state.role ) ? <ErrorModal text="Lost previous data"/> : null }
        <div className={styles.base__container}>
          <div className={styles.answer__container}>
            <QuestionBase title={`What is your age?`}>
              <div className={styles.mcq__container}>
                {selectOptions.map( (el,index) => {
                  return (
                    <SelectionButton
                      key={index}
                      text={el}
                      fill
                      outline={age !== el}
                      clickHandler={() => setAge(el)}
                    />
                  );
                })}
              </div>
            </QuestionBase>
          </div>
          <div className={styles.button__container}>
            <Button
              text="Next"
              fill
              disabled={!age}
              clickHandler={() =>
                submitHandler(age, UPDATE_AGE)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(QuestionAge);
