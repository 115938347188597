import {
  CLEAR_SERVICE_DETAILS,
  UPDATE_NAME,
  UPDATE_ROLE,
  UPDATE_AGE,
  UPDATE_INTEREST,
  UPDATE_EMAIL,
} from "../actionTypes";

const initialState = {
  sceneNum: 0,
  name: "",
  role: "student",
  age: "",
  interest: [],
  email:"",
};

const questionTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NAME:
      const name = action.payload.answer;
      const words = name.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
      }
      return {
        ...state,
        name: words.join(" "),
        sceneNum: 1,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        role: action.payload.answer,
        sceneNum: 2,
      };
    case UPDATE_AGE:
      return {
        ...state,
        age: action.payload.answer,
        sceneNum: 3,
      };
    case UPDATE_INTEREST:
      return {
        ...state,
        interest: action.payload.answer.sort(),
        sceneNum: 4,
      };
    case UPDATE_EMAIL:
        return {
          ...state,
          email: action.payload.answer,
          sceneNum: 5,
    };
    case CLEAR_SERVICE_DETAILS:
      return initialState;
    default:
      return state;
  }
};
export default questionTrackerReducer;
