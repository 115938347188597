import AscSelection from "./typeDrawers/ascTypeSelection";
import BodySelection from "./typeDrawers/bodyTypeSelection";
import ClothesTopSelection from "./typeDrawers/clothesTopTypeSelection";
import EyesSelection from "./typeDrawers/eyeTypeSelection";
import HairSelection from "./typeDrawers/hairTypeSelection";
import MouthSelection from "./typeDrawers/mouthTypeSelection";
import ShoesSelection from "./typeDrawers/shoesTypeSelection";
import TransportSelection from "./typeDrawers/transportTypeSelection";
import ClothesBottomSelection from './typeDrawers/clothesBottomTypeSelection';

export default function TypeDrawer(props) {
  switch (props.category) {
    case "bodyType":
      return <BodySelection />;
    case "eyes":
      return <EyesSelection />;
    case "mouth":
      return <MouthSelection />;
    case "hair":
      return <HairSelection />;
    case "asc":
      return <AscSelection />;
    case "clothesTop":
      return <ClothesTopSelection/>;
    case "clothesBottom":
      return <ClothesBottomSelection/>;
    case "shoes":
      return <ShoesSelection />;
    case "transport":
      return <TransportSelection />;
    default:
      return <MouthSelection />;
  }
}
