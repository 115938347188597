import styles from "../../../styles/AvatarDisplay.module.css";
import cx from "classnames";
import { connect, useDispatch } from "react-redux";
import { UPDATE_AVATAR_PART } from "../../../redux/actionTypes";
import {
  clothesSetTypeOptions,
  clothesBottomTypeOptions,
} from "../../../dataTypes";

const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function ClothesBottomSelection(state) {
  const dispatch = useDispatch();
  const avatarPart = "clothesBottom";
  const setPart = "clothesSet";
  function updateAvatarPart(part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: avatarPart,
        selection: part,
      },
    });
  }

  function updateSetAvatarPart(part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: setPart,
        selection: part,
      },
    });
  }

  function compareAvatar(cur, part) {
    return cur === part;
  }
  return (
    <div className={styles.selection__container}>
      <div className={styles.selection__grid}>
        {clothesSetTypeOptions[state.type].map((set, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model[setPart],
                  set
                ),
              })}
              onClick={() => updateSetAvatarPart(set)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${set}.png`}
                alt={`clothes-set-${index}`}
              />
            </div>
          );
        })}
        {clothesBottomTypeOptions.map((bottom, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model[avatarPart],
                  bottom
                ),
              })}
              onClick={() => updateAvatarPart(bottom)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${bottom}.png`}
                alt={`clothes-bottom-${index}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ClothesBottomSelection);
