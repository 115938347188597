import styles from "../../../styles/AvatarDisplay.module.css";
import cx from "classnames";
import { connect, useDispatch } from "react-redux";
import { UPDATE_AVATAR_PART } from "../../../redux/actionTypes";
import {
  ascSpectaclesTypeOptions,
  ascMoustacheTypeOptions,
  ascBeardTypeOptions,
  ascEarringsTypeOptions,
} from "../../../dataTypes";

const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function AscSelection(state) {
  const dispatch = useDispatch();
  function updateAvatarPart(type, part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: type,
        selection: part,
      },
    });
  }

  function compareAvatar(cur, part) {
    return cur === part;
  }
  return (
    <div className={styles.selection__container}>
      <div className={styles.selection__title}>Spectacles</div>
      <div className={styles.selection__grid}>
        <div
          className={cx(styles.selection, {
            [styles.selection__selected]: compareAvatar(
              state.model["ascSpectacles"],
              ""
            ),
          })}
          onClick={() => updateAvatarPart("ascSpectacles", "")}
        >
          <img
                className={styles.contained__image}
                src={`/icons/None.svg`}
                alt={`specs-none`}
              />
        </div>
        {ascSpectaclesTypeOptions.map((specs, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model["ascSpectacles"],
                  specs
                ),
              })}
              onClick={() => updateAvatarPart("ascSpectacles", specs)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${specs}.png`}
                alt={`specs-${index}`}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.selection__title}>Moustache</div>
      <div className={styles.selection__grid}>
        <div
          className={cx(styles.selection, {
            [styles.selection__selected]: compareAvatar(
              state.model["ascMoustache"],
              ""
            ),
          })}
          onClick={() => updateAvatarPart("ascMoustache", "")}
        >
          <img
                className={styles.contained__image}
                src={`/icons/None.svg`}
                alt={`moustache-none`}
              />
        </div>
        {ascMoustacheTypeOptions.map((moustache, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model["ascMoustache"],
                  moustache
                ),
              })}
              onClick={() => updateAvatarPart("ascMoustache", moustache)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${moustache}.png`}
                alt={`moustache-${index}`}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.selection__title}>Beard</div>
      <div className={styles.selection__grid}>
        <div
          className={cx(styles.selection, {
            [styles.selection__selected]: compareAvatar(
              state.model["ascBeard"],
              ""
            ),
          })}
          onClick={() => updateAvatarPart("ascBeard", "")}
        >
          <img
                className={styles.contained__image}
                src={`/icons/None.svg`}
                alt={`beard-none`}
              />
        </div>
        {ascBeardTypeOptions.map((beard, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model["ascBeard"],
                  beard
                ),
              })}
              onClick={() => updateAvatarPart("ascBeard", beard)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${beard}.png`}
                alt={`beard-${index}`}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.selection__title}>Earring</div>
      <div className={styles.selection__grid}>
        <div
          className={cx(styles.selection, {
            [styles.selection__selected]: compareAvatar(
              state.model["ascEarrings"],
              ""
            ),
          })}
          onClick={() => updateAvatarPart("ascEarrings", "")}
        >
          <img
                className={styles.contained__image}
                src={`/icons/None.svg`}
                alt={`earrings-none`}
              />
        </div>
        {ascEarringsTypeOptions.map((earrings, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model["ascEarrings"],
                  earrings
                ),
              })}
              onClick={() => updateAvatarPart("ascEarrings", earrings)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${earrings}.png`}
                alt={`earrings-${index}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(AscSelection);
