import { Component } from "react";
import styles from "./../../styles/AvatarDisplay.module.css";
import { connect } from "react-redux";
import {
  bodyTypeOptions,
  eyesTypeOptions,
  hairTypeOptions,
  mouthTypeOptions,
  clothesTopTypeOptions,
  clothesBottomTypeOptions,
  clothesSetTypeOptions,
  ascBeardTypeOptions,
  ascEarringsTypeOptions,
  ascMoustacheTypeOptions,
  ascSpectaclesTypeOptions,
  shoesTypeOptions,
  transportTypeOptions,
} from "../../dataTypes";

import { SET_PRELOAD_STATE } from "./../../redux/actionTypes";

const mapStateToProps = (state) => {
  return { ...state.avatar };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPreloadState: () =>
      dispatch({
        type: SET_PRELOAD_STATE,
        payload: { loaded: true },
      }),
  };
};
const { createjs } = window;

class AssetPreloader extends Component {
  state = {
    prgs: 0,
    loadRun: false,
  };

  componentDidMount() {
    if (!this.state.loadRun) {
      this.loadAssets();
    }
  }

  loadAssets() {
    if (this.props.type) {
      this.setState({
        ...this.state,
        loadedRun: true,
      });

      const queue = new createjs.LoadQueue(false);
      queue.on("progress", this.handleProgress, this);
      queue.on("complete", this.handleComplete, this);

      queue.loadManifest(
        bodyTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   bodyTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        eyesTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   eyesTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      
      queue.loadManifest(
        hairTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      queue.loadManifest(
        mouthTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   mouthTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        clothesTopTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   clothesTopTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        clothesBottomTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   clothesBottomTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        clothesSetTypeOptions[this.props.type].map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   clothesSetTypeOptions[this.props.type].map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        shoesTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   shoesTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        transportTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   transportTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        ascBeardTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   ascBeardTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        ascMoustacheTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   ascMoustacheTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        ascSpectaclesTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   ascSpectaclesTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.loadManifest(
        ascEarringsTypeOptions.map((el) => {
          return {
            id: el,
            src: `/avatar-builder/${this.props.type}/${this.props.type}-${el}.png`,
          };
        })
      );
      // queue.loadManifest(
      //   ascEarringsTypeOptions.map((el) => {
      //     return {
      //       id: el,
      //       src: `/category-selector/icon-${el}.png`,
      //     };
      //   })
      // );
      queue.load();
    }
  }

  handleProgress(e) {
    let percent = Math.round(e.progress * 100);
    this.setState({
      ...this.state,
      prgs: percent,
    });
  }

  handleComplete() {
    this.props.setPreloadState();
  }

  render() {
    return <div className={styles.loadingPercent}>{`${this.state.prgs}%`}</div>;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssetPreloader);
