import styles from "../../../styles/AvatarDisplay.module.css";
import cx from "classnames";
import { connect, useDispatch } from "react-redux";
import { bodyTypeOptions } from "../../../dataTypes";
import { UPDATE_AVATAR_PART } from "../../../redux/actionTypes";
const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function BodySelection(state) {
  const dispatch = useDispatch();
  const avatarPart = "bodyType";
  function updateAvatarPart(part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: avatarPart,
        selection: part,
      },
    });
  }

  function compareAvatar(cur, part) {
    return cur === part;
  }
  return (
    <div className={styles.selection__container}>
      <div className={styles.selection__grid}>
        {bodyTypeOptions.map((body, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model[avatarPart],
                  body
                ),
              })}
              onClick={() => updateAvatarPart(body)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${body}.png`}
                alt={`body-${index}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(BodySelection);
