import styles from "../../../styles/AvatarDisplay.module.css";
import cx from "classnames";
import { connect, useDispatch } from "react-redux";
import { UPDATE_AVATAR_PART } from "../../../redux/actionTypes";
import {
  clothesSetTypeOptions,
  clothesTopTypeOptions,
} from "./../../../dataTypes";

const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function ClothesTopSelection(state) {
  const dispatch = useDispatch();
  const avatarPart = "clothesTop";
  const setPart = "clothesSet";
  function updateAvatarPart(part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: avatarPart,
        selection: part,
      },
    });
  }
  function updateSetAvatarPart(part) {
    dispatch({
      type: UPDATE_AVATAR_PART,
      payload: {
        type: setPart,
        selection: part,
      },
    });
  }

  function compareAvatar(cur, part) {
    return cur === part;
  }
  return (
    <div className={styles.selection__container}>
      <div className={styles.selection__grid}>
        {clothesSetTypeOptions[state.type].map((set, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model[setPart],
                  set
                ),
              })}
              onClick={() => updateSetAvatarPart(set)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${set}.png`}
                alt={`clothes-set-${index}`}
              />
            </div>
          );
        })}
        {clothesTopTypeOptions.map((top, index) => {
          return (
            <div
              key={index}
              className={cx(styles.selection, {
                [styles.selection__selected]: compareAvatar(
                  state.model[avatarPart],
                  top
                ),
              })}
              onClick={() => updateAvatarPart(top)}
            >
              <img
                className={styles.contained__image}
                src={`/category-selector/icon-${top}.png`}
                alt={`clothes-top-${index}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ClothesTopSelection);
