import { createRef, useRef } from "react";
import { useDispatch } from "react-redux";
import styles from "../../styles/AvatarDisplay.module.css";
import SkinIcon from "../../assets/images/category-icon/skin-icon.png";
import EyesIcon from "../../assets/images/category-icon/eyes-icon.png";
import MouthIcon from "../../assets/images/category-icon/mouth-icon.png";
import HairIcon from "../../assets/images/category-icon/hair-icon.png";
import AscIcon from "../../assets/images/category-icon/asc-icon.png";
import TopIcon from "../../assets/images/category-icon/top-icon.png";
import BottomIcon from "../../assets/images/category-icon/bottom-icon.png";
import ShoesIcon from "../../assets/images/category-icon/shoes-icon.png";
import TransportIcon from "../../assets/images/category-icon/transport-icon.png";
import cx from "classnames";
import { SELECT_CATEGORY, SET_VIEW } from "../../redux/actionTypes";
export default function CategorySelector(props) {
  const dispatch = useDispatch();
  const iconButtons = useRef([]);
  const scroller = createRef();
  const categories = [
    {
      id: "bodyType",
      image: SkinIcon,
      required: true,
    },
    {
      id: "eyes",
      image: EyesIcon,
      required: true,
    },
    {
      id: "mouth",
      image: MouthIcon,
      required: true,
    },
    {
      id: "hair",
      image: HairIcon,
      required: false,
    },
    {
      id: "asc",
      image: AscIcon,
      required: false,
    },
    {
      id: "clothesTop",
      image: TopIcon,
      required: true,
    },
    {
      id: "clothesBottom",
      image: BottomIcon,
      required: true,
    },
    {
      id: "shoes",
      image: ShoesIcon,
      required: true,
    },
    {
      id: "transport",
      image: TransportIcon,
      required: true,
    },
  ];

  iconButtons.current = categories.map(
    (element, i) => iconButtons.current[i] ?? createRef()
  );
  function scrollToHandler(index, ref) {
    const moveVal =
      index * (ref.current.clientWidth + 8) > scroller.current.clientWidth / 2
        ? index * (ref.current.clientWidth + 8) -
          scroller.current.clientWidth / 2 +
          ref.current.clientWidth / 2
        : -ref.current.clientWidth / 2;
    scroller.current.scrollTo({ left: moveVal });
    dispatch({
      type: SELECT_CATEGORY,
      payload: {
        selection: categories[index].id,
      },
    });
    if (index > 0 && index < 5) {
      dispatch({
        type: SET_VIEW,
        payload: {
          view: "bust",
        },
      });
    } else {
      dispatch({
        type: SET_VIEW,
        payload: {
          view: "full",
        },
      });
    }
  }

  function checkRequired(obj, cat) {
    if (cat.required) {
      switch (cat.id) {
        case "clothesTop":
          return obj[cat.id] !== "" || obj["clothesSet"] !== "";
        case "clothesBottom":
          return obj[cat.id] !== "" || obj["clothesSet"] !== "";
        case "shoes":
          return obj[cat.id] !== "" || obj["clothesSet"] !== "";
        default:
          return obj[cat.id] !== "";
      }
    }
    return true;
  }

  return (
    <div className={styles.horizontal__container}>
      <div className={styles.slider__container} ref={scroller}>
        {categories.map((cat, index) => {
          return (
            <div
              className={cx(styles.category__container, {
                [styles.active__category]: props.category === cat.id,
                [styles.required__category]:
                  props.requiredWarning &&
                  !checkRequired(props.model, cat) &&
                  props.category !== cat.id,
              })}
              ref={iconButtons.current[index]}
              key={index}
              onClick={() => scrollToHandler(index, iconButtons.current[index])}
            >
              <img className={styles.contained__image} src={cat.image} alt={`category+${index}`} />
              {props.requiredWarning &&
              !checkRequired(props.model, cat) &&
              props.category !== cat.id ? (
                <div className={styles.warning__icon}>
                  <img className={styles.contained__image} src="/icons/WarningIcon.svg" alt={"warning-icon"} />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}
