import styles from "../styles/Buttons.module.css";
import cx from "classnames";
export default function SelectionButton(props) {
  let className = cx(styles.button__style, {
    [styles.outlined__button]: props.outline,
    [styles.selected__button]: !props.outline,
    [styles.fill__container]: props.fill,
  });

  return (
    <button
      disabled={props.disabled}
      className={className}
      onClick={() => {
        props.clickHandler();
      }}
    >
      {props.text}
      {props.multi ? (
        props.checked ? (
          <img
            className={styles.checkedIcon}
            src="/icons/CheckedCircle.svg"
            alt="checked"
          />
        ) : (
          <img
            className={styles.checkIcon}
            src="/icons/CheckCircle.svg"
            alt="check"
          />
        )
      ) : null}
    </button>
  );
}
