import { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import styles from "../styles/Modal.module.css";
import { CLOSE_ERROR_MODAL_STATE } from './../redux/actionTypes';

const mapStateToProps = (state) => {
  return { ...state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch({
      type: CLOSE_ERROR_MODAL_STATE,
    })
  }
}

class ErrorModal extends Component {
  state = {
    modalOn: true,
  };
  componentDidMount() {
    setTimeout(() => {
      
      this.setState({
        ...this.state,
        modalOn: false,
      });
      if(this.props.modalOnly){
        this.props.closeModal()
      }
    }, 2000);
  }
  render() {
    return (
      <>
        {this.state.modalOn ? (
          <div className={styles.error__modal__container}>
            {this.props.text}
          </div>
        ) : !this.props.modalOnly ? (
          <Navigate to="/" />
        ) : null}
      </>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ErrorModal);
