import { connect } from "react-redux";
import styles from "../styles/Builder.module.css";
import AvatarDisplay from "../components/avatarSelection/avatarDisplay";
import CategorySelector from "./../components/avatarSelection/categorySelector";
import TypeDrawer from "../components/avatarSelection/typeDrawer";
import ClothesWarningModal from "../components/clothesWarningModal";
import ErrorModal from "./../components/errorModal";
import AssetPreloader from "../components/avatarSelection/assetPreloader";
const mapStateToProps = (state) => {
  return { ...state };
};

function BuildMe(state) {
  return (
    <div className="base__mobile__container">
      <div className="mobile__preview">
        {state &&
        (!state.questions.name ||
          !state.questions.role ||
          !state.questions.age) ? (
          <ErrorModal text="Lost previous data" />
        ) : null}
        {state &&
        state.avatar.requiredWarning &&
        state.avatar.requiredModalState ? (
          <ErrorModal
            modalOnly={true}
            text="Please select all required options before continuing."
          />
        ) : null}
        <div className={styles.base__container}>
          {state.avatar.loaded ? (
            <div className={styles.avatar__builder}>
              <AvatarDisplay />
              <CategorySelector
                requiredWarning={state.avatar.requiredWarning}
                model={state.avatar.model}
                category={state.avatar.category}
              />
              <TypeDrawer category={state.avatar.category} />
            </div>
          ) : (
            <AssetPreloader />
          )}
        </div>
        <ClothesWarningModal />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(BuildMe);
