import styles from "../../styles/Avatar.module.css";
import cx from "classnames";
import Button from "../../components/button";
import { useDispatch } from "react-redux";
import { START_FROM_SCRATCH, START_FROM_PRESET } from "../../redux/actionTypes";
import { presetTypeOptions } from "../../dataTypes";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
function PresetSelector(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [presetId, setPresetIdSelection] = useState("");
  const [presetModel, setPresetModelSelection] = useState({});
  const type =
    props.type === "Student/Youth/Fresh graduate" ? "student" : "pmet-mid";

  function presetStart(type, part) {
    dispatch({
      type: START_FROM_PRESET,
      payload: {
        presetModel: part,
        selection: type,
      },
    });
    navigate("/build-me");
  }

  function start(type) {
    dispatch({
      type: START_FROM_SCRATCH,
      payload: { selection: type },
    });
    navigate("/build-me");
  }

  return (
    <div className={styles.base__container}>
      <div className={styles.preset__container}>
        <div className={styles.title}>
          Awesome. Now on to the fun part - building your avatar!
        </div>
        <div className={styles.subtext}>
          Select a preset to start editing <br />
          quickly, or
          <span
            className={styles.scratch__button}
            onClick={() => start(props.type)}
          >
            {" "}
            start from scratch.{" "}
          </span>
        </div>
        <div className={styles.presets}>
          {presetTypeOptions[type].map((preset, index) => {
            return (
              <div
                key={index}
                className={cx(styles.preset__selection, {
                  [styles.preset__selected]: preset.id === presetId,
                })}
                onClick={() => {
                  setPresetIdSelection(preset.id);
                  setPresetModelSelection(preset.preset);
                }}
              >
                <img src={`/preset-selector/${type}/${index+1}.png`} alt={`preset-${index}`} />
                {preset.id === presetId ? (
                  <div className={styles.checkedIcon}>
                    <img src="/icons/CheckedCircle.svg" alt="checked" />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.button__container}>
        <Button
          text="Let's Go!"
          disabled={!presetId}
          fill
          clickHandler={() => presetStart(props.type, presetModel)}
        />
      </div>
    </div>
  );
}

export default PresetSelector;
