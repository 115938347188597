import styles from "../styles/Modal.module.css";
import { connect, useDispatch } from "react-redux";
import Button from "./button";
import { UPDATE_WARNING_STATE } from "./../redux/actionTypes";

const mapStateToProps = (state) => {
  return { ...state.avatar };
};

function ClothesWarningModal(state) {
  const dispatch = useDispatch();

  function allowOverrideHandler() {
    dispatch({
      type: UPDATE_WARNING_STATE,
      payload: {
        override: true,
      },
    });
  }

  function cancelOverrideHandler() {
    dispatch({
      type: UPDATE_WARNING_STATE,
      payload: {
        override: false,
      },
    });
  }

  return (
    <>
      {state.warning ? (
        <div className={styles.modal__container}>
          <div className={styles.text__container}>
            Selecting this outfit will override your avatar's existing clothes.
            <br />
            Continue?
            <div className={styles.button__container}>
              <Button red text="Cancel" clickHandler={cancelOverrideHandler} />
              <Button text="Continue" clickHandler={allowOverrideHandler} />
            </div>
          </div>
          <div className={styles.modal__blackout}></div>
        </div>
      ) : null}
    </>
  );
}


export default connect(mapStateToProps)(ClothesWarningModal);;
