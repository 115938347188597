import { useState } from "react";
import styles from "../styles/Questionnaire.module.css";
import Button from "../components/button";
import QuestionBase from "../components/questionBase";
import { connect, useDispatch } from "react-redux";
import { UPDATE_EMAIL } from "../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import ErrorModal from "./../components/errorModal";
const mapStateToProps = (state) => {
  return { ...state };
};

function QuestionEmail(state) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState(state.questions.email);

  function submitHandler(value, dispatchAction) {
    dispatch({
      type: dispatchAction,
      payload: {
        answer: value,
      },
    });
    navigate("/finale");
  }
  return (
    <div className="base__mobile__container">
      <div className="mobile__preview">
        {state &&
        (!state.questions.name ||
          !state.questions.role ||
          !state.questions.age ||
          !state.questions.interest) ? (
          <ErrorModal text="Lost previous data" />
        ) : null}
        <div className={styles.base__container}>
          <div className={styles.answer__container}>
            <QuestionBase title={`Almost there! What is your email address?`}>
              <div className={styles.body__text}>
                You'll receive a customized emailer from us. PS: We'll only use
                this to get in touch, not to send spam.
              </div>
              <input
                type="email"
                autoComplete="email"
                placeholder="your email"
                className={styles.input__container}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </QuestionBase>
          </div>
          <div className={styles.button__container}>
            <Button
              text="Next"
              fill
              disabled={!email}
              clickHandler={() => submitHandler(email, UPDATE_EMAIL)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(QuestionEmail);
