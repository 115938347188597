import {
  FORM_SEND_SUCCESS,
  FORM_SEND_ERROR,
  FORM_SENDING,
  CLEAR_FORM,
} from "../actionTypes";

const initialState = {
  sending: false,
  formSent: false,
  formError: false
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_SENDING:
      return {
        ...state,
        sending: true,
        formSent: false,
      };
    case FORM_SEND_SUCCESS:
      return {
        ...state,
        sending: false,
        formSent: true,
      };
    case FORM_SEND_ERROR:
      return {
        ...state,
        sending: false,
        formSent: false,
        formError: true,
      };
    case CLEAR_FORM:
      return initialState;
    default:
      return state;
  }
};
export default formReducer;
