import {
  START_FROM_SCRATCH,
  START_FROM_PRESET,
  UPDATE_AVATAR_PART,
  SELECT_CATEGORY,
  RANDOMISE_AVATAR,
  SET_VIEW,
  RESET_AVATAR,
  UPDATE_WARNING_STATE,
  MERGE_IMAGE,
  FINISH_AVATAR,
  CLOSE_ERROR_MODAL_STATE,
  SET_PRELOAD_STATE,
} from "../actionTypes";
import { bodyTypeOptions } from "../../dataTypes";
const initialPending = {
  clothesTop: "",
  clothesBottom: "",
  shoes: "",
  clothesSet: "",
};

const initialState = {
  started: false,
  requiredWarning: false,
  requiredModalState: false,
  warning: false,
  pending: initialPending,
  type: "pmet-mid",
  category: "bodyType",
  view: "bust",
  loaded: false,
  mergedImage: "",
  model: {
    bodyType: "",
    transport: "",
    shoes: "",
    clothesBottom: "",
    clothesTop: "",
    clothesSet: "",
    eyes: "",
    mouth: "",
    hair: "",
    ascSpectacles: "",
    ascBeard: "",
    ascMoustache: "",
    ascEarrings: "",
  },
};

const avatarTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AVATAR_PART:
      let updatedAvatar = { ...state.model };
      switch (action.payload.type) {
        case "clothesTop":
          if (state.model.clothesSet !== "") {
            let updatedPending = { ...state.pending };
            updatedPending["clothesTop"] = action.payload.selection;
            return {
              ...state,
              model: updatedAvatar,
              pending: updatedPending,
              warning: true,
            };
          } else {
            updatedAvatar[action.payload.type] = action.payload.selection;
          }
          break;
        case "clothesBottom":
          if (state.model.clothesSet !== "") {
            let updatedPending = { ...state.pending };
            updatedPending["clothesBottom"] = action.payload.selection;
            return {
              ...state,
              model: updatedAvatar,
              pending: updatedPending,
              warning: true,
            };
          } else {
            updatedAvatar[action.payload.type] = action.payload.selection;
          }
          break;
        case "shoes":
          if (state.model.clothesSet !== "") {
            let updatedPending = { ...state.pending };
            updatedPending["shoes"] = action.payload.selection;
            return {
              ...state,
              model: updatedAvatar,
              pending: updatedPending,
              warning: true,
            };
          } else {
            updatedAvatar[action.payload.type] = action.payload.selection;
          }
          break;
        case "clothesSet":
          if (
            state.model.clothesTop !== "" ||
            state.model.clothesBottom !== "" ||
            state.model.shoes !== ""
          ) {
            let updatedPending = { ...state.pending };
            updatedPending["clothesSet"] = action.payload.selection;
            return {
              ...state,
              model: updatedAvatar,
              pending: updatedPending,
              warning: true,
            };
          } else {
            updatedAvatar[action.payload.type] = action.payload.selection;
          }
          break;
        default:

        //do nothing
      }
      updatedAvatar[action.payload.type] = action.payload.selection;
      return {
        ...state,
        model: updatedAvatar,
      };
    case START_FROM_PRESET:
      let bodyType = "";
      if (action.payload.selection === "Student/Youth/Fresh graduate") {
        bodyType = "student";
      } else {
        bodyType = "pmet-mid";
      }
      return {
        ...state,
        type: bodyType,
        started: true,
        model: action.payload.presetModel,
      };
    case START_FROM_SCRATCH:
      let bodyyType = "";
      if (action.payload.selection === "Student/Youth/Fresh graduate") {
        bodyyType = "student";
      } else {
        bodyyType = "pmet-mid";
      }
      let updateddAvatar = { ...initialState.model };
      updateddAvatar.bodyType = bodyTypeOptions[0];
      return {
        ...state,
        type: bodyyType,
        started: true,
        model: updateddAvatar,
      };
    case SELECT_CATEGORY:
      return {
        ...state,
        category: action.payload.selection,
      };
    case SET_VIEW:
      return {
        ...state,
        view: action.payload.view,
      };
    case RANDOMISE_AVATAR:
      let randomAvatar = { ...state.model };
      // Presets
      return {
        ...state,
        model: randomAvatar,
      };
    case RESET_AVATAR:
      return initialState;
    case UPDATE_WARNING_STATE:
      let updatedddAvatar = { ...state.model };
      if (action.payload.override) {
        for (const [k, v] of Object.entries(state.pending)) {
          updatedddAvatar[k] = v;
        }
      }
      return {
        ...state,
        warning: false,
        pending: initialPending,
        model: updatedddAvatar,
      };
    case FINISH_AVATAR:
      return {
        ...state,
        requiredWarning: true,
        requiredModalState: action.payload.requiredModalState,
      };
    case MERGE_IMAGE:
      return {
        ...state,
        mergedImage: action.payload.mergedImage,
      };
    case CLOSE_ERROR_MODAL_STATE:
      return {
        ...state,
        requiredModalState: false,
      };
    case SET_PRELOAD_STATE:
      return {
        ...state,
        loaded: action.payload.loaded,
      };
    default:
      return state;
  }
};
export default avatarTrackerReducer;
