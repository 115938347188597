export const bodyTypeOptions = ["body-1", "body-2", "body-3", "body-4"];

export const ascBeardTypeOptions = ["beard-1", "beard-2", "beard-3"];

export const ascEarringsTypeOptions = [
  "earrings-1",
  "earrings-2",
  "earrings-3",
];

export const ascMoustacheTypeOptions = [
  "moustache-1",
  "moustache-2",
  "moustache-3",
];

export const ascSpectaclesTypeOptions = ["spectacles-1"];

export const clothesBottomTypeOptions = [
  "jeans-1",
  "jeans-2",
  "jeans-3",
  "pants-1",
  "pants-2",

  "pants-3",

  "shorts-1",

  "shorts-2",

  "shorts-3",

  "skirt-1",

  "skirt-2",

  "skirt-3",
];

export const clothesSetTypeOptions = {
  "pmet-mid": ["suit-1", "suit-2", "suit-3", "suit-4"],
  student: ["uniform-1", "uniform-2"],
};

export const clothesTopTypeOptions = [
  "polo-1",

  "polo-2",

  "polo-3",

  "pullover-1",

  "pullover-2",

  "pullover-3",

  "tshirt-1",

  "tshirt-2",

  "tshirt-3",
];

export const eyesTypeOptions = [
  "eyes-1",

  "eyes-2",

  "eyes-3",

  "eyes-4",

  "eyes-5",
];

export const hairTypeOptions = [
  "hair-1-1",

  "hair-1-2",

  "hair-1-3",

  "hair-2-1",

  "hair-2-2",

  "hair-2-3",

  "hair-3-1",

  "hair-3-2",

  "hair-3-3",

  "hair-4-1",

  "hair-4-2",

  "hair-4-3",

  "hair-5-1",

  "hair-5-2",

  "hair-5-3",

  "hair-6-1",

  "hair-6-2",

  "hair-6-3",

  "hair-7-1",

  "hair-7-2",

  "hair-7-3",

  "hair-8-1",

  "hair-8-2",

  "hair-8-3",
];

export const mouthTypeOptions = [
  "mouth-1",

  "mouth-2",

  "mouth-3",

  "mouth-4",

  "mouth-5",
];

export const shoesTypeOptions = [
  "sneakers-1",

  "sneakers-2",

  "sneakers-3",

  "heels-1",

  "heels-2",

  "heels-3",

  "shoes-1",

  "shoes-2",

  "shoes-3",
];

export const transportTypeOptions = [
  "transport-1",

  "transport-2",

  "transport-3",
];

export const presetTypeOptions = {
  "pmet-mid": [
    {
      id: "preset-1",
      preset: {
        bodyType: "body-1",
        transport: "transport-2",
        shoes: "",
        clothesBottom: "",
        clothesTop: "",
        clothesSet: "suit-3",
        eyes: "eyes-3",
        mouth: "mouth-2",
        hair: "hair-7-2",
        ascSpectacles: "spectacles-1",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "earrings-1",
      },
    },

    {
      id: "preset-2",

      preset: {
        bodyType: "body-1",
        transport: "transport-2",
        shoes: "",
        clothesBottom: "",
        clothesTop: "",
        clothesSet: "suit-1",
        eyes: "eyes-3",
        mouth: "mouth-2",
        hair: "hair-2-3",
        ascSpectacles: "",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },
    {
      id: "preset-3",

      preset: {
        bodyType: "body-2",
        transport: "transport-3",
        shoes: "heels-2",
        clothesBottom: "skirt-3",
        clothesTop: "pullover-3",
        clothesSet: "",
        eyes: "eyes-5",
        mouth: "mouth-5",
        hair: "hair-6-2",
        ascSpectacles: "",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },

    {
      id: "preset-4",

      preset: {
        bodyType: "body-3",
        transport: "transport-1",
        shoes: "sneakers-1",
        clothesBottom: "jeans-2",
        clothesTop: "pullover-2",
        clothesSet: "",
        eyes: "eyes-4",
        mouth: "mouth-5",
        hair: "hair-1-1",
        ascSpectacles: "",
        ascBeard: "beard-1",
        ascMoustache: "",
        ascEarrings: "",
      },
    },

    {
      id: "preset-5",

      preset: {
        bodyType: "body-4",
        transport: "transport-1",
        shoes: "",
        clothesBottom: "",
        clothesTop: "",
        clothesSet: "suit-4",
        eyes: "eyes-4",
        mouth: "mouth-3",
        hair: "hair-8-2",
        ascSpectacles: "",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },

    {
      id: "preset-6",

      preset: {
        bodyType: "body-4",
        transport: "transport-3",
        shoes: "",
        clothesBottom: "",
        clothesTop: "",
        clothesSet: "suit-1",
        eyes: "eyes-1",
        mouth: "mouth-2",
        hair: "hair-4-3",
        ascSpectacles: "spectacles-1",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },
  ],
  student: [
    {
      id: "preset-1",

      preset: {
        bodyType: "body-2",
        transport: "transport-2",
        shoes: "",
        clothesBottom: "",
        clothesTop: "",
        clothesSet: "uniform-1",
        eyes: "eyes-3",
        mouth: "mouth-2",
        hair: "hair-7-2",
        ascSpectacles: "spectacles-1",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },
    {
      id: "preset-2",

      preset: {
        bodyType: "body-2",
        transport: "transport-2",
        shoes: "",
        clothesBottom: "",
        clothesTop: "",
        clothesSet: "uniform-2",
        eyes: "eyes-3",
        mouth: "mouth-2",
        hair: "hair-2-3",
        ascSpectacles: "",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },
    {
      id: "preset-3",

      preset: {
        bodyType: "body-2",
        transport: "transport-3",
        shoes: "heels-2",
        clothesBottom: "skirt-3",
        clothesTop: "pullover-3",
        clothesSet: "",
        eyes: "eyes-5",
        mouth: "mouth-5",
        hair: "hair-6-2",
        ascSpectacles: "",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "earrings-1",
      },
    },
    {
      id: "preset-4",

      preset: {
        bodyType: "body-3",
        transport: "transport-1",
        shoes: "sneakers-1",
        clothesBottom: "jeans-2",
        clothesTop: "pullover-2",
        clothesSet: "",
        eyes: "eyes-4",
        mouth: "mouth-5",
        hair: "hair-1-1",
        ascSpectacles: "",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },
    {
      id: "preset-5",

      preset: {
        bodyType: "body-4",
        transport: "transport-1",
        shoes: "sneakers-1",
        clothesBottom: "pants-3",
        clothesTop: "pullover-1",
        clothesSet: "",
        eyes: "eyes-4",
        mouth: "mouth-3",
        hair: "hair-8-2",
        ascSpectacles: "",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },
    {
      id: "preset-6",

      preset: {
        bodyType: "body-4",
        transport: "transport-3",
        shoes: "sneakers-1",
        clothesBottom: "shorts-1",
        clothesTop: "tshirt-3",
        clothesSet: "",
        eyes: "eyes-1",
        mouth: "mouth-2",
        hair: "hair-4-3",
        ascSpectacles: "",
        ascBeard: "",
        ascMoustache: "",
        ascEarrings: "",
      },
    },
  ],
};
